
import downloadFile from '@/utils/claim/downloadFile';
import { getPaymentTrackingReportTable } from '@/API/salesReporting/claimCheckingReport';
import moment from 'moment';
import { defineComponent, ref, reactive, toRefs, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { qmOptions } from '@/views/SalesReporting/type';
import { message, TreeSelect } from 'ant-design-vue';
import DM from '../components/downloadModal.vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

interface SearchDataType {
    bu: string | undefined;
    year: string | undefined;
    quarter: Array<string>;
}

export default defineComponent({
    components: {
        DM,
    },
    setup() {
        const spaceSize = 6;
        const tableHeight = (window.innerHeight - 510) / 2;
        const downloadType = ref(2);
        const downloadTypeVisible = ref(false);
        const columns1 = ref<any[]>([]);
        const columns2 = ref<any[]>([]);

        const dataSource1 = ref<any[]>([]);
        const dataSource2 = ref<any[]>([]);
        const totalSource = ref<any[]>([]);

        const searchData = reactive<SearchDataType>({
            bu: undefined,
            year: undefined,
            quarter: [],
        });
        watch(
            () => searchData.year,
            (newVal) => {
                if (!newVal) {
                    searchData.quarter = [];
                }
            }
        );
        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        let resetBu = '';
        const store = useStore();
        const buList = computed(() =>
            store.state.user.buList.filter((bu: any) => {
                return bu.parentId === null;
            })
        );

        const reset = () => {
            searchData.bu = resetBu;
            searchData.year = moment().format('YYYY');
            searchData.quarter = [`Q${moment().quarter()}`];
            // searchData.quarter = [undefined];
        };

        const getParams = (isDownload: boolean) => {
            const params: {
                bu: string | undefined;
                month: string[];
                year: string | undefined;
            } = {
                bu: searchData.bu,
                month: [],
                year: searchData.year,
            };

            const monthList: any[] = [];
            const qm = searchData.quarter;
            console.log('searchData.quarter', searchData.quarter);
            qm.forEach((qm: string) => {
                if (qm[0] === 'Q') {
                    switch (qm[1]) {
                        case '1':
                            monthList.push(...['1', '2', '3']);
                            break;
                        case '2':
                            monthList.push(...['4', '5', '6']);
                            break;
                        case '3':
                            monthList.push(...['7', '8', '9']);
                            break;
                        case '4':
                            monthList.push(...['10', '11', '12']);
                            break;
                    }
                } else {
                    monthList.push(qm as string);
                }
            });
            params.month = monthList;

            if (isDownload) {
                return Object.assign({ fileNameList: ['2', '3'], ...params });
            }

            return params;
        };

        const getTableData = () => {
            if (!searchData.year || !searchData.bu) {
                message.error('BU and Year is required condition');
                return;
            }

            const params = getParams(false);
            getPaymentTrackingReportTable(params).then((res) => {
                if (params.bu != 'VAN') {
                    dataSource1.value = res.noDemoCarList2;
                    totalSource.value = [res.demoCarList2.pop()];
                    console.log('totalSource.value', totalSource.value);
                    dataSource2.value = res.demoCarList2;
                    columns1.value = [
                        {
                            title: '',
                            dataIndex: 'title',
                            key: 'title',
                            width: 100,
                        },
                        {
                            title: 'PBP',
                            children: [
                                {
                                    title: ' 不含税',
                                    dataIndex: 'pbpAmountWOVat',
                                    key: 'pbpAmountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' 含税',
                                    dataIndex: 'pbpAmountWVat',
                                    key: 'pbpAmountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'pbpProgramNo',
                                    key: 'pbpProgramNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'pbpDealerNo',
                                    key: 'pbpDealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'pbpClaimNo',
                                    key: 'pbpClaimNo',
                                    width: 100,
                                },
                            ],
                        },
                        {
                            title: 'CBU',
                            children: [
                                {
                                    title: ' 不含税',
                                    dataIndex: 'cbuAmountWOVat',
                                    key: 'cbuAmountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' 含税',
                                    dataIndex: 'cbuAmountWVat',
                                    key: 'cbuAmountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'cbuProgramNo',
                                    key: 'cbuProgramNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'cbuDealerNo',
                                    key: 'cbuDealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'cbuClaimNo',
                                    key: 'cbuClaimNo',
                                    width: 100,
                                },
                            ],
                        },
                    ];
                    columns2.value = [
                        {
                            title: '',
                            dataIndex: 'title',
                            key: 'title',
                            width: 100,
                        },
                        {
                            title: 'PBP',
                            children: [
                                {
                                    title: ' Demo Car-不含税 ',
                                    dataIndex: 'pbpAmountWOVat',
                                    key: 'pbpAmountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' Demo Car-含税 ',
                                    dataIndex: 'pbpAmountWVat',
                                    key: 'pbpAmountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'pbpProgramNo',
                                    key: 'pbpProgramNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'pbpDealerNo',
                                    key: 'pbpDealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'pbpClaimNo',
                                    key: 'pbpClaimNo',
                                    width: 100,
                                },
                            ],
                        },
                        {
                            title: 'CBU',
                            children: [
                                {
                                    title: ' Demo Car-不含税',
                                    dataIndex: 'cbuAmountWOVat',
                                    key: 'cbuAmountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' Demo Car-含税',
                                    dataIndex: 'cbuAmountWVat',
                                    key: 'cbuAmountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'cbuProgramNo',
                                    key: 'cbuProgramNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'cbuDealerNo',
                                    key: 'cbuDealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'cbuClaimNo',
                                    key: 'cbuClaimNo',
                                    width: 100,
                                },
                            ],
                        },
                    ];
                } else {
                    //
                    dataSource1.value = res.noDemoCarList1;
                    dataSource2.value = res.demoCarList1;
                    columns1.value = [
                        {
                            title: '',
                            dataIndex: 'title',
                            key: 'title',
                            width: 100,
                        },
                        {
                            title: 'VAN',
                            children: [
                                {
                                    title: ' 不含税',
                                    dataIndex: 'amountWOVat',
                                    key: 'amountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' 含税',
                                    dataIndex: 'amountWVat',
                                    key: 'amountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'programNo',
                                    key: 'programNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'dealerNo',
                                    key: 'dealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'claimNo',
                                    key: 'claimNo',
                                    width: 100,
                                },
                            ],
                        },
                    ];
                    columns2.value = [
                        {
                            title: '',
                            dataIndex: 'title',
                            key: 'title',
                            width: 100,
                        },
                        {
                            title: 'VAN',
                            children: [
                                {
                                    title: ' Demo Car-不含税 ',
                                    dataIndex: 'amountWOVat',
                                    key: 'amountWOVat',
                                    width: 120,
                                },
                                {
                                    title: ' Demo Car-含税 ',
                                    dataIndex: 'amountWVat',
                                    key: 'amountWVat',
                                    width: 120,
                                },
                                {
                                    title: 'Program No.',
                                    dataIndex: 'programNo',
                                    key: 'programNo',
                                    width: 100,
                                },
                                {
                                    title: 'Dealer No.',
                                    dataIndex: 'dealerNo',
                                    key: 'dealerNo',
                                    width: 100,
                                },
                                {
                                    title: 'Claim No.',
                                    dataIndex: 'claimNo',
                                    key: 'claimNo',
                                    width: 100,
                                },
                            ],
                        },
                    ];
                }
            });
        };

        const download = () => {
            if (!searchData.year || !searchData.bu) {
                message.error('BU and Year is required condition');
                return;
            }
            downloadTypeVisible.value = true;
        };

        const handleOk = (data: Array<any>) => {
            if (data.length > 1) {
                downloadType.value = 4;
            } else {
                downloadType.value = data[0].sign;
            }
            const params = getParams(true);
            const array = [];
            if (downloadType.value == 4) {
                array.push(3);
                array.push(2);
            } else {
                array.push(downloadType.value);
            }
            params.fileNameList = array;
            const downloadParams = {
                url: `/rpapi/paymentTracking/report/downloadPaymentTracking`,
                // url: `/ptapi/paymentTracking/report/downloadPaymentTracking`,
                method: 'post',
                params: params,
            };
            downloadFile(
                downloadParams,
                'application/vnd-excel; char-set=UTF-8'
            ).then((res: any) => {
                downloadTypeVisible.value = false;
            });
        };

        const dogetDefault = () => {
            const mbIndex = buList.value.findIndex((i: any) => {
                return i.nameEn === 'MBPC';
            });
            const initIndex = mbIndex === -1 ? 0 : mbIndex;
            searchData.bu = buList.value[initIndex].nameEn;
            resetBu = buList.value[initIndex].nameEn;
            searchData.year = moment().format('YYYY');
            searchData.quarter = [`Q${moment().quarter()}`];
            getTableData();
        };
        dogetDefault();
        const downloadTableData = [
            {
                file: 'Raw Data',
                sign: 2,
            },
            {
                file: 'Report Data',
                sign: 3,
            },
        ];

        return {
            spaceSize,
            tableHeight,
            downloadTableData,
            downloadTypeVisible,
            handleOk,
            dataSource2,
            dataSource1,
            totalSource,
            download,
            getTableData,
            reset,
            SHOW_PARENT,
            qmOptions,
            ...toRefs(searchData),
            buList,
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            columns1,
            columns2,
            downloadType,
        };
    },
});
